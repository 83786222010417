import React from "react";

function About() {
  return (
    <div>
      <div className="ltn__utilize-overlay" />
      {/* SLIDER AREA START (slider-3) */}
      <div
        className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
        data-bg="img/bg/9.jpg"
        style={{ backgroundImage: 'url("img/bg/9.jpg")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                <div className="section-title-area ltn__section-title-2">
                  <h6
                    className="section-subtitle ltn__secondary-color"
                    style={{ color: "#fff !important" }}
                  >
                    Welcome to Kera-Fresh
                  </h6>
                  <h1 className="section-title white-color">Who We Are.?</h1>
                </div>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <a href="/" style={{ color: "#fff" }}>
                        Home
                      </a>
                    </li>
                    <li style={{ color: "#fff" }}>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ABOUT US AREA START */}
      <div className="ltn__about-us-area  pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2">
                  <h6 className="section-subtitle ltn__secondary-color">
                    Know More About Kera Fresh
                  </h6>
                  <h1 className="section-title">
                    Trusted Organic <br className="d-none d-md-block" /> Food
                    Store
                  </h1>
                  <p style={{ textAlign: "justify" }}>
                    Welcome to our website! We are a Registered Farmer Producer
                    Company dedicated to promoting traditionally value-added
                    products from our farms.
                  </p>
                </div>
                <p style={{ textAlign: "justify" }}>
                  We believe in developing unique products that supplement the
                  medicinal value in farm products. Our team of experts is
                  constantly exploring new ways to create products that not only
                  taste great but also offer health benefits. We are committed
                  to providing our customers with the best quality products that
                  promote a healthy lifestyle. We work closely with our farmers
                  to collect the best quality raw materials to create our unique
                  range of products that are rich in flavor and nutritional
                  value. Our products include jaggery, sugar, honey, and brown
                  sugar, all of which are made without any chemical
                  preservatives. We strongly believe in promoting the use of
                  natural and organic ingredients to ensure that our products
                  retain the rich flavor and nutritional value of traditional
                  farm produce. One of our core principles is to retain the
                  nutritional values of traditional farm products. We understand
                  the importance of preserving the natural goodness of our
                  products, which is why we use traditional techniques to create
                  our unique range of products. Our jaggery, sugar, honey, and
                  brown sugar are rich in essential nutrients that are vital for
                  a healthy lifestyle.
                </p>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img src="img/other/6.png" alt="About Us Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ABOUT US AREA END */}
      <div className="ltn__feature-area section-bg-1 pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2 text-center">
                <h6 className="section-subtitle ltn__secondary-color"></h6>
                <h1 className="section-title">
                  Why Choose Us<span>.</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-7">
                <div className="ltn__feature-icon-title">
                  <div className="ltn__feature-icon">
                    <span>
                      <img src="img/icons/icon-img/21.png" alt="#" />
                    </span>
                  </div>
                  <h3 className="animated fadeIn">
                    <a href="service-details.html">Natural &amp; Organic</a>
                  </h3>
                </div>
                <div className="ltn__feature-info">
                  <p style={{ textAlign: "justify", letterSpacing: "1px" }}>
                    We use organic ingredients to create safer and
                    eco-friendlier products that support your health and
                    well-being.Choose organic products that you can feel good
                    about.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-7">
                <div className="ltn__feature-icon-title">
                  <div className="ltn__feature-icon">
                    <span>
                      <img src="img/icons/icon-img/23.png" alt="#" />
                    </span>
                  </div>
                  <h3 className="animated fadeIn">
                    <a href="service-details.html">Budget Friendly</a>
                  </h3>
                </div>
                <div className="ltn__feature-info">
                  <p
                    style={{
                      textAlign: "justify",
                      wordSpacing: "1px",
                    }}
                  >
                    We're committed to making high-quality natural and organic
                    products affordable for everyone. We keep our prices
                    budget-friendly without sacrificing quality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-7">
                <div className="ltn__feature-icon-title">
                  <div className="ltn__feature-icon">
                    <span>
                      <img src="img/icons/icon-img/22.png" alt="#" />
                    </span>
                  </div>
                  <h3 className="animated fadeIn">
                    <a href="service-details.html">Chemical Free</a>
                  </h3>
                </div>
                <div className="ltn__feature-info">
                  <p style={{ textAlign: "justify", wordSpacing: "1px" }}>
                    We believe your health should never be compromised by
                    harmful chemicals. That's why we only use natural and
                    organic ingredients, and never harsh chemicals or synthetic
                    fragrances.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
